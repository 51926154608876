import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { graphql } from 'gatsby';
import { chakra, Container, HStack, VStack, Text, Highlight } from '@chakra-ui/react';

import { DefaultLayout, Card, MessageLayout, PageSwitchLayout } from 'components/layout';
import { VectorBackground } from 'components/view';
import { SubscribeForm } from 'components/form';
import { CurlyArrow } from 'components/visual';
import { MetaData } from 'components/meta';

const DomainPage = ({ location }) => {
    const { t } = useTranslation();
    const [subscriptionState, setSubscriptionState] = useState({
        visible: false,
    });

    const params = new URLSearchParams(location.search);
    const contactData = {
        name: decodeURI(params.get('name')) || 'unknown name',
    };

    const bodyDescription = t('domain_main_message', { url: contactData.name });
    const ActionComponent = () => <Card>
        <VStack alignItems="stretch" w="full" gap={2}>
            <VStack alignItems="start">
                <HStack>
                    <chakra.h1>{t('subscribe_title')}</chakra.h1>
                    <CurlyArrow color="primary.300" />
                </HStack>
                <Text>
                    <Highlight
                        query={[contactData.name]}
                        styles={{ px: '2', py: '1', rounded: 'lg', color: 'black', bg: 'secondary.300' }}>
                        {bodyDescription}
                    </Highlight>
                </Text>
            </VStack>
            <SubscribeForm
                fromPath={location.pathname + location.search}
                onSubscribeSuccess={({ email }) => {
                    setSubscriptionState({
                        visible: true,
                        icon: 'bx-message-alt-check',
                        title: t('subscribe_state_success'),
                        message: t('subscribe_state_success_message', { email }),
                    });
                }}
                onSubscribeFail={({ email }, message) => {
                    setSubscriptionState({
                        visible: true,
                        icon: 'bx-message-alt-error',
                        title: t('subscribe_state_error'),
                        message: t('subscribe_state_error_message', { email }),
                    });
                }} />
        </VStack>
    </Card>;

    const PageInitial = () => <Container
        maxW="container.md"
        p={{ base: 3, md: 16 }}>
        <ActionComponent />
    </Container>;

    const PageFinal = () => <MessageLayout
        icon={subscriptionState.icon}
        title={subscriptionState.title}
        message={subscriptionState.message} />;

    return (<>
        <MetaData location={location} />
        <DefaultLayout>
            <VectorBackground
                pattern="plaid"
                scale={10}
                colors={[
                    'secondary.300',
                    'rgb(196,212,175)',
                    'rgb(176,229,216)',
                    'cyan.50',
                ]}
                w="full"
                pb={12}
                pt={{ base: '4rem', md: '7rem' }}>
                <PageSwitchLayout
                    pageInitial={<PageInitial />}
                    pageFinal={<PageFinal />}
                    showFinalPage={subscriptionState.visible} />
            </VectorBackground>
        </DefaultLayout>
    </>)
};

export default DomainPage;

export const PageDomainPageQuery = graphql`
query PageDomainPageQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["common", "domain"]}}) {
        ...TranslationFragment
    }
}`;
